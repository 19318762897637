<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="File Inputs">
                    <template v-slot:description>
                        <div>import { HbFileInput } from 'hummingbird-aviary';</div>
                        <div class="mt-2">This component has not been fully tested for production use, please report any issues you find when using this component by posting a message in the #design-system slack channel.</div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card class="mt-4 mb-6">

            <template v-slot:title>
                <span class="pr-3">File Input + Code</span> <span class="hb-text-light hb-font-body font-weight-regular">( You can use all the same prop, slots, and events as vuetify 'v-file-input' component. )</span>
            </template>

            <div class="pa-4">
                <HbFileInput v-model="files1" />
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbFileInput v-model="files1" />
</pre>
            </hb-card>

        </hb-card>

        <hb-card title="File Input in an HbForm row" class="mt-4 mb-6">

            <hb-form label="File Input">
                <HbFileInput v-model="files2" />
            </hb-form>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbFileInput v-model="files2" />
</pre>
            </hb-card>

        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <!--
        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>
        -->

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemFileInputs",
        data: function() {
            return {
                files1: [],
                files2: [],
                propHeaders: [
                    { text: 'Name', value: 'name', width: '140' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Required', value: 'required', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'full', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true, this changes the file input max-width from the standard 370px to no limit/full width.' },
                    { name: 'placeholder', type: 'string', default: 'Select to Add a File', required: 'no',  description: 'Use this if you need to change the default placeholder text.' },
                ],
                /*
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                ],
                */
            };
        },
    }
</script>

<style scoped>

</style>
